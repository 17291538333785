.c-modal {
    .modal-wrapper {
        left: 50%;
        padding: 1rem;
        position: fixed;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        z-index: 2147483647; // Above backdrop
    }

    .modal {
        align-items: center;
        background-color: #fff;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        padding: 1.2rem;
        text-align: center;
        width: 100%;

        .close {
            text-align: right;
            width: 100%;

            img {
                cursor: pointer;
            }
        }

        h1 {
            font-size: 26px;
            font-weight: 700;
            line-height: 36px;
            text-align: center;
            margin-bottom: 0;

            a {
                color: $color-secondary;
            }
        }

        p {
            font-family: $body-font-family;
            font-size: 1.6rem;
            margin: 1.2rem 0;
        }

        .actions {
            display: flex;
            margin-top: 14px;

            button, a {
                margin-right: 1.2rem;

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
    }
}
