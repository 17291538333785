.c-jwplayer {
  position: relative;
}

/**
 * Makes sure video is moving with mobile site navbar.
 */
@import './video/navbar-management';

/**
 * Untouched files from `se-video-pages` and `se-calico`.
 */
@import './video/main';
@import './video/unmute-button';
@import './video/small-sticky';
@import './video/small-sticky-ads';

/**
 * Overrides for recept.se
 */
// Align with floating cookie on desktop.
.jw-flag-floating.jw-floating-dismissible .jw-wrapper.jw-reset {
  bottom: 2rem;
}