button,
input,
optgroup,
select,
textarea {
  font-family: $body-font-family;
  padding: 0;
  line-height: inherit;
  color: inherit;
  box-sizing: border-box;
}

input::placeholder {
  color: #5f5f5f;
}

[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: button;
}

[role="button"],
button {
  cursor: pointer;
}

button {
  background: transparent;
  background-color: transparent;
  padding: 0;
}

button,
select {
  text-transform: none;
}

button,
input {
  overflow: visible;
}
