.scaling-card-image {
  .card-image {
    transition: transform .2s ease-in;
  }

  &:hover {
    .card-image {
      transform: scale(1.1);
    }
  }
}
