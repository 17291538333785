h1,
h2,
h3,
h4 {
  font-family: $headline-font-family;
  font-size: 26px;
  font-weight: inherit;
  line-height: 36px;
  padding: 0;
  margin: 0 0 $inuit-global-spacing-unit;

  @include mq($until: tablet) {
    margin: 0 0 $inuit-global-spacing-unit-small;
  }
}

h1 {
  font-size: 4rem;
  font-weight: bold;
}

h2 {
  font-family: $headline-font-family;
  font-size: 3.2rem;
  font-weight: normal;
}

h3 {
  font-size: 2.8rem;
  font-weight: bold;
  margin: 0;
}

h4 {
  font-size: 2.4rem;
}

h5 {
  font-size: 2.2rem;
}

h6 {
  font-size: 2rem;
}
