.link,
.link:hover,
.link:active,
.link:visited {
  color: $color-secondary;
  text-decoration: underline;
}


// Save this in global CSS.
picture.four {
  img {
      position: absolute;
      width: 50% !important;
      height: auto !important;

      &.pos-1 {
          right: 0;
          top: 0;
      }
      &.pos-2 {
          bottom: 0;
      }
      &.pos-3 {
          bottom: 0;
          right: 0;
      }
  }
}
