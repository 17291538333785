// Colors.
// Defaults
$color-primary:           #A7D4C3;
$color-secondary:         #286052;
$color-background:        #f6f6f6;
$color-dark:              #333;
$color-black:             #000;
$color-white:             #fff;
$color-orange:            #fcb75a;

$color-grey-dark:         #525252;
$color-grey:              #a0aec0;
$color-grey-medium:       #c0c0c0;
$color-grey-light:        #cecece;
$color-grey-lightest:     #f3f3f3;

$color-warning-red:       #a33f40;
$color-red:               #bd081c;

$color-pink:              #fcded6;

$color-focus:             #5e9ed6;

// Assigned variants
$color-primary-dark:   darken($color-primary, 5%);
$color-primary-darker: darken($color-primary, 10%);
$color-primary-light:  lighten($color-primary, 30%);
$color-link:           $color-primary;
$color-border:         $color-dark;
$color-headings:       $color-dark;

$color-ui-greyscale-50: #f2f2f2;
$color-ui-greyscale-200: #dbdbdb;
$color-ui-greyscale-300: #acacac;
$color-ui-greyscale-700: #404040;
$color-brand-melon-50: #FDF0EC;
$color-brand-melon-200: #FFCEBF;
$color-brand-melon-300: #F1BEAF;
$color-brand-melon-700: #735146;
$color-brand-green-sheen-300: #87BCA8;
$color-brand-green-sheen-700: #286052;
$color-ui-error-500: #FF3000;
$color-ui-error-600: #B11E00;

// Font.
$body-font-family: nunito, sans-serif;
$headline-font-family: 'ITC Cheltenham W05',georgia,serif;
$serif-font-family: 'ITC Cheltenham W05',georgia,serif; // For shared CSS with backend UI.

// Brand colors.
$brand-facebook:     #3b5998;
$brand-instagram:    #c13584;
$brand-pinterest:    #bd081c;
$brand-twitter:      #1da1f2;
$brand-youtube:      #f00;

// Font Awesome.
$fa-font-path:      "/assets/fonts/FontAwesome" !default;
$fa-font-size-base: 1.4rem !default;

// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$grid-breakpoints: (
  xxs: 0,
  xs: 340px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  hd: 1400px,
  uhd: 1680px
) !default;

// Global padding
$global-padding-mob:    1rem;
$global-padding-desk:   2rem;

// Font sizes
$font-size-xxs:                         1.25rem;
$font-size-xs:                          1.3rem;
$font-size-sm:                          1.4rem;

$font-size-base:                        1.5rem;

$font-size-md:                          1.6rem;
$font-size-lg:                          1.7rem;
$font-size-xl:                          1.8rem;
$font-size-xxl:                         1.9rem;

$font-size-h0-1: 7rem;
$font-size-h0: 6rem;
$font-size-h1-1: 5rem;
$font-size-h1-0: 4.8rem;
$font-size-h1: 4rem;
$font-size-h2: 3.2rem;
$font-size-h2-2: 3.6rem;
$font-size-h3: 2.8rem;
$font-size-h4-2: 2.6rem;
$font-size-h4: 2.4rem;
$font-size-h5-1: 2.3rem;
$font-size-h5: 2.2rem;
$font-size-h6-1: 2.1rem;
$font-size-h6: 2rem;

$line-height-tight: 1.25;
$line-height-loose: 2;

$inuit-offsets: true;
$inuit-global-spacing-unit: 2rem;
$inuit-global-spacing-unit-large: 5rem;

$inuit-wrapper-width: 1240px;

// Define default breakpoints.
$breakpoint-mobile: 576px !default;
$breakpoint-tablet: 768px !default;
$breakpoint-desktop: 992px !default;
$breakpoint-wide: 1200px !default;

// To enable support for browsers that do not support @media queries,
// (IE <= 8, Firefox <= 3, Opera <= 9) set $mq-responsive to false
// Create a separate stylesheet served exclusively to these browsers,
// meaning @media queries will be rasterized, relying on the cascade itself
$mq-responsive: true !default;

// Name your breakpoints in a way that creates a ubiquitous language
// across team members. It will improve communication between
// stakeholders, designers, developers, and testers.
$mq-breakpoints: (
  mobile: $breakpoint-mobile,
  tablet: $breakpoint-tablet,
  desktop: $breakpoint-desktop,
  wide: $breakpoint-wide
) !default;

// Define the breakpoint from the $mq-breakpoints list that should
// be used as the target width when outputting a static stylesheet
// (i.e. when $mq-responsive is set to 'false').
$mq-static-breakpoint: desktop !default;

// If you want to display the currently active breakpoint in the top
// right corner of your site during development, add the breakpoints
// to this list, ordered by width, e.g. (mobile, tablet, desktop).
$mq-show-breakpoints: ();
