.c-dropdown {
  position: relative;
  font-family: $body-font-family;

  &__options {
    -webkit-box-shadow: 0 11px 32px 5px rgba(0, 0, 0, 0.42);
    animation-duration: 0.5s;
    animation-name: dropdownAppear;
    background-color: #dbdbdb;
    border-radius: 2.2rem;
    box-shadow: 0 11px 32px 5px rgba(0, 0, 0, 0.42);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 5.5rem;
    z-index: 2147483648;
  }

  &__option {
    background-color: #fff;
    display: flex;
    font-size: 1.6rem;
    line-height: 5.4rem;
    margin-bottom: 1px;
    padding: 0 1.6rem;
    white-space: nowrap;

    &:hover {
      background-color: #eaf7f1;
    }

    &--active {
      font-weight: bold;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    img {
      margin-right: 0.6rem;
      max-width: unset;
    }
  }

  @keyframes dropdownAppear {
    from {
      opacity: 0;
      transform: translateY(-22px);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
