/** Positioning of to bar lines (heights) depending on menu state */
body.top-bar-hidden #top-bar {
  transform: translateY(-6rem);

  @include mq($from: mobile) {
    transform: translateY(-7.2rem);
  }
}

body.top-bar-hidden.has-trending-links #top-bar {
  transform: translateY(-10.8rem);

  @include mq($from: mobile) {
    transform: translateY(-12.8rem);
  }
}

.c-topbar {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 50;
  transform: translateY(0);
  transition-property: transform;
  transition-duration: 0.3s;

  &__row > div {
    width: 100%;
    max-width: $breakpoint-wide;
  }

  .c-primary-nav {
    color: $color-white;
    display: flex;
    height: 6rem;
    padding-left: 1rem;
    padding-right: 1rem;
    z-index: 45;

    &__container {
      align-items: center;
      display: flex;
      margin-left: auto;
      margin-right: auto;
    }

    &__toggle-button {
      align-items: center;
      background-color: #fafafa;
      border-radius: 1.8rem;
      display: flex;
      height: 3.6rem;
      justify-content: center;
      width: 3.6rem;

      img {
        height: 2.4rem;
        width: 2.4rem;
      }

      &:focus { outline: none; }
    }

    form {
      position: relative;
      flex-grow: 1;
      height: 3.6rem;
      color: $color-grey-dark;

      input {
        position: absolute;
        border-radius: 1.8rem;
        border: 1px solid $color-secondary;
        font-size: 1.6rem;
        height: 3.6rem;
        padding: 0.6rem 1rem 0.6rem 2rem;
        width: 100%;
      }

      button {
        background-color: #f3f3f3;
        border-radius: 1.2rem;
        font-size: 1.4rem;
        height: 2.4rem;
        margin-right: 1rem;
        margin-top: -0.1rem;
        outline: none;
        position: absolute;
        right: 0;
        top: 0.7rem;
        width: 2.4rem;
      }
    }

    @include mq($from: mobile) {
      height: 7.2rem;

      &__toggle-button {
        border-radius: 2rem;
        font-size: 1.6rem;
        height: 4rem;
        width: 4rem;

        &--menu img {
          height: 1.8rem;
          width: 1.8rem;
        }
      }

      form {
        height: 4rem;

        input {
          border-radius: 2rem;
          font-size: 1.6rem;
          height: 4rem;
        }

        button {
          border-radius: 1.4rem;
          height: 2.8rem;
          width: 2.8rem;
        }
      }
    }

    .close-search {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 16px;
        width: 16px;
      }
    }
  }

  &--search-open {
    .c-primary-nav {
      @include mq($until: mobile) {
        &__menu-button,
        &__logo {
          display: none;
        }
      }

      @include mq($from: tablet) {
        form {
          max-width: 30rem;
        }
      }
    }
  }
}

@keyframes menuin {
  from {
    top: -40px;
    clip-path: inset(40px 0 0 0);
    opacity: 0;
  }

  to {
    top: 0;
    clip-path: inset(0 0 0 0);
    opacity: 1;
  }
}

.c-topbar--menu-open {
  .c-menu {
    &__container {
      animation-duration: 0.3s;
      animation-name: menuin;
    }
  }
}

.c-menu {
  position: relative;
  z-index: 40;

  &__container {
    background-color: #fff;
    padding: 2.4rem 1.6rem;
    position: absolute;
    width: 100%;
    height: calc(100vh - 6rem);
  }

  &__content {
    font-family: $body-font-family;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    .dashed-divider {
      box-sizing: border-box;
      height: 1px;
      width: 100%;
      border-bottom: 1px dashed $color-secondary;
      margin-bottom: 1.6rem;
    }
  }

  &__links {
    margin-bottom: 1.6rem;

    a {
      color: $color-secondary;
      display: flex;
      flex-direction: row;
      font-size: 1.8rem;
      font-weight: 600;
      line-height: 3.6rem;
      margin-bottom: 0.8rem;
    }
  }

  &__social {
    font-size: 1.6rem;

    > div {
      font-weight: 600;
      margin-bottom: 1.6rem;
    }

    a {
      display: inline-flex;
      margin-right: 1.2rem;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  @include mq($from: mobile) {
    &__container {
      background-color: #fff;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.14);
      height: auto;
      padding: 1.6rem 1rem;
    }

    &__content {
      flex-direction: row;

      .dashed-divider {
        display: none;
      }
    }

    &__links {
      flex-grow: 1;
      margin-bottom: 0;
    }

    &__social {
      > div {
        font-size: 1.8rem;
      }

      a {
        border-radius: 2rem;
        height: 4rem;
        line-height: 4rem;
        margin-right: 1.2rem;
        width: 4rem;
      }
    }
  }
}

.c-advanced-search-bar {
  background-color: #fff;
  display: flex;
  padding-left: 1rem;
  padding-right: 1rem;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.18);

  // &__container {
  //   align-items: center;
  //   display: flex;
  //   padding: .7rem 0;
  //   margin-left: auto;
  //   margin-right: auto;
  // }
}

@media print {
  .c-topbar {
    display: none !important;
  }
}
