@mixin article-content {
  color: #404040;

  h2, h3, h4 {
    font-family: $serif-font-family;
    font-weight: bold;
  }

  h2, h3, h4, li {
    margin-bottom: 1.2rem;
  }

  h2 {
    font-size: 2.5rem;
    line-height: 3.0rem;
  }

  h3 {
    font-size: 2.0rem;
    line-height: 2.4rem;
  }

  h4 {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }

  p {
    font-size: 1.6rem;
    line-height: 150%;
    margin-bottom: 2.4rem;
  }

  span,
  a,
  li {
    font-size: 1em;
    line-height: inherit;
  }

  a,
  a span {
    color: $color-secondary !important;
    font-weight: bold !important;
    text-decoration: underline;
  }

  ol,
  ul {
    margin-bottom: 2.4rem;
    line-height: inherit;
    list-style: initial;
    margin-left: 3rem;
  }

  ol {
    list-style-type: decimal;
  }

  p + ol,
  p + ul {
    margin-top: -1.4rem;
  }

  @include mq($from: tablet) {
    h2, h3, h4, li {
      margin-bottom: 1.6rem;
    }

    h2 {
      font-size: 3.3rem;
      line-height: 4.0rem;
    }

    h3 {
      font-size: 2.5rem;
      line-height: 3.0rem;
    }

    h4 {
      font-size: 2.0rem;
      line-height: 2.4rem;
    }

    p {
      font-size: 2.0rem;
      margin-bottom: 3.2rem;
    }

    ol,
    ul {
      margin-bottom: 3.2rem;
    }
  }

  .fr-video {
    margin-left: auto;
    margin-right: auto;
    display: table;

    iframe {
      max-width: 100%;
    }
  }

  .fr-video.fr-fvr {
    margin-left: auto;
    margin-right: 0;
    display: table;
  }

  .fr-video.fr-fvl {
    margin-right: auto;
    margin-left: 0;
    display: table;
  }

  .fr-img-caption .fr-img-wrap {
    padding: 0;
    margin: auto;
    text-align: center;
    width: 100%;

    > span {
      margin: auto;
      display: block;
      padding: 5px 5px 10px;
      font-size: 14px;
      font-weight: initial;
      box-sizing: border-box;
      opacity: 0.9;
      width: 100%;
      text-align: center;
    }
  }

  blockquote {
    font-size: $font-size-h2;
    font-style: italic;
    line-height: 1.2;
    font-weight: bold;
    display: block;
    position: relative;
    max-width: 80%;
    text-align: center;
    margin: 0 auto;
    padding-top: 2rem;
    padding-bottom: 2rem;
    color: $color-white;
    text-shadow: -0.1rem -0.1rem 0 $color-secondary, 0 0 0 $color-secondary, 0 0.1rem 0 $color-secondary, 0.1rem 0 0 $color-secondary, 0.1rem 0.1rem 0 $color-secondary, 0.2rem 0.2rem 0 $color-secondary, 0.3rem 0.3rem 0 $color-secondary, 0.4rem 0.4rem 0 $color-secondary;

    @include mq($from: tablet) {
      max-width: 60%;
    }

    &::before {
      content: "";
      background-image: url('../images/icons/quotation_mark.svg');
      display: inline-block;
      height: 3rem;
      width: 3.4rem;
      position: absolute;
      margin-left: -4.4rem;
      margin-top: -2rem;
      background-size: contain;
      background-repeat: no-repeat;
    }

    &::after {
      content: "";
      background-image: url('../images/icons/quotation_mark.svg');
      display: inline-block;
      height: 3rem;
      width: 3.4rem;
      position: absolute;
      margin-left: 1.8rem;
      margin-top: 2.8rem;
      background-size: contain;
      background-repeat: no-repeat;
      transform: rotate(180deg);
    }
  }

  .jwplayer,
  .fr-jwplayer {
    margin-bottom: 3.1rem;
  }
}
