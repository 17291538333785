.c-modal-v2 {
    .modal-wrapper {
        left: 50%;
        padding: 1rem;
        position: fixed;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        z-index: 2147483647; // Above backdrop
    }

    .modal {
        align-items: center;
        background-color: #fff;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        width: 100%;

        &__title-bar {
            border-bottom: 1px solid #f2f2f2;
            display: flex;
            justify-content: space-between;
            margin-bottom: 2.4rem;
            padding: 1.4rem 1.2rem 1rem 1.2rem;
            width: 100%;

            .title {
                flex-grow: 1;
                font-family: $headline-font-family;
                font-size: 1.9rem;
                line-height: 2.3rem;
            }

            .close {
                text-align: right;

                img {
                    cursor: pointer;
                    min-height: 24px;
                    min-width: 24px;
                }
            }
        }

        &__content {
            padding: 0 2.4rem;
            width: 100%;
        }

        &__actions {
            display: flex;
            padding: 2.4rem 1.2rem;

            button {
                margin-right: 1.2rem;

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
    }

    @include mq($from: desktop) {
        .modal {
            &__title-bar {
                padding: 1.8rem;

                .title {
                    font-size: 2.5rem;
                    line-height: 3rem;
                }

                .close {
                    padding-top: .2rem;
                }
            }

            &__actions {
                padding: 1.8rem;
            }
        }
    }
}
