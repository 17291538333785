@viewport,
  @-ms-viewport {
  width: device-width;
  initial-scale: 1;
}

html {
  line-height: 1.5;
  font-size: 10px;
  box-sizing: border-box;
}

p {
  font-size: $font-size-xl;
}

a {
  color: $color-secondary;
}

figure {
  font-size: $font-size-md;
}

[style*="--aspect-ratio"] > :first-child {
  width: 100%;
}

[style*="--aspect-ratio"] > img {
  height: auto;
}

@supports (--custom:property) {
  [style*="--aspect-ratio"] {
    position: relative;
  }

  [style*="--aspect-ratio"]::before {
    content: "";
    display: block;
    padding-bottom: calc(100% / (var(--aspect-ratio)));
  }

  [style*="--aspect-ratio"] > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
}

@media print {
  //   // html, body, #app, #site-container, .site-content {
  html, body {
    width: 210mm;
    height: 100%;
  }
}