.top_ad {
    position: absolute;
    z-index: 0;
}

@media print {
    .adnm-html-topscroll-frame-wrapper,
    .adnm-topscroll-text-box,
    .adnm-html-topscroll-frame,
    .adnm-scroll-down-btn {
        display: none !important;
    }
}