.c-login-button {
    position: relative;
    height: 3.6rem;
    width: 3.6rem;
    margin-left: 12px;

    button {
        align-items: center;
        background-color: #fafafa;
        border-radius: 1.8rem;
        display: flex;
        height: 3.6rem;
        justify-content: center;
        left: 0;
        position: absolute;
        top: 0;
        width: 3.6rem;
    }

    .arrow {
        border-color: #fafafa transparent transparent transparent;
        border-style: solid;
        border-width: 11px 14px 0 14px;
        display: none;
        height: 0;
        left: 4px;
        position: absolute;
        top: 29.73px;
        width: 0;
        z-index: 1;
    }

    .menu {
        background-color: #fff;
        border-radius: 0px 0px 10px 10px;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.178492);
        display: none;
        font-family: $body-font-family;
        font-size: 16px;
        padding: 12px;
        position: absolute;
        right: 0;
        top: 48px;
        width: 264px;
        z-index: 55;

        .title {
            color: #000;
            font-weight: 600;
            margin-bottom: 0.5rem;
        }

        .link {
            text-decoration: none;
            display: flex;
            height: 32px;
            line-height: 32px;

            .icon {
                text-align: center;
                width: 24px;

                img {
                    margin-top: -3px;
                    vertical-align: middle;
                    display: inline;
                }
            }

            span {
                flex-grow: 1;
                margin-left: 12px;
            }
        }
    }

    &.open {
        .arrow {
            display: block;
        }
        .menu {
            display: block;
        }
    }

    @include mq($from: mobile) {
        height: 4rem;
        width: 4rem;

        button {
            border-radius: 2rem;
            height: 4rem;
            width: 4rem;
        }

        .arrow {
            border-width: 15px 18px 0 18px;
            left: 2px;
            top: 30px;
        }

        .menu {
            top: 56px;
        }
    }
}