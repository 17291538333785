/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-style-focus-change:focus {
  outline: none;
  box-shadow: 0 0 2px 4px $color-primary;
}

@media (min-width: 768px) {
  .table {
    table-layout: fixed;
  }

  .table-title {
    width: 60%;
  }
}

textarea.c-input-text-v2 {
  display: block;
  line-height: 2.4rem;
  min-height: 4.4rem;
  padding: 1rem 1.6rem;

  &:hover,
  &:focus
   {
    line-height: 2.4rem;
  }
}

.c-input-text-v2 {
  -webkit-appearance: none !important;
  background-color: #fff;
  border-radius: 2.2rem;
  border: 1px solid $color-ui-greyscale-300;
  box-sizing: border-box;
  color: #000;
  font-size: 1.6rem;
  line-height: 4.2rem;
  padding: 0 1.6rem;

  &:hover {
    border: 1px solid $color-brand-green-sheen-700;
    box-shadow: 0 0 0px 1px $color-brand-green-sheen-700;
  }

  &:focus {
    border: 1px solid $color-brand-green-sheen-300;
    outline: none;
  }

  &--invalid {
    border: 1px solid $color-ui-error-500;

    &:hover {
      border: 1px solid $color-ui-error-600;
    }

    &:focus {
      border: 1px solid $color-ui-error-600;
      box-shadow: none;
      outline: none;
    }
  }

  &--disabled,
  &--disabled:hover,
  &--disabled::placeholder {
    border-color: #dbdbdb;
    box-shadow: none;
    color: #acacac;
  }
}

.c-checkbox-v2 {
  display: flex;
  align-items: flex-start;

  &--disabled {
    color: #acacac;

    .c-checkbox-v2__checkbox {
      border-color: #dbdbdb;
    }

    .c-checkbox-v2__checkbox--checked {
      background-color: #dbdbdb;
    }
  }

  &__checkbox {
    background-color: #ffffff;
    border-radius: 3px;
    border: 1px solid $color-secondary;
    cursor: pointer;
    display: flex;
    height: 2.4rem;
    margin-right: .8rem;
    position: relative;
    width: 2.4rem;
    min-width: 2.4rem;

    &--checked {
      background-color: $color-secondary;
    }

    &--invalid {
      border: 1px solid $color-ui-error-600;
    }

    img {
      position: absolute;
      user-select: none;
      left: 0.1rem;
      top: 0.3rem;
    }
  }

  &__label {
    font-size: 1.4rem;
    margin-top: 0.2rem;
  }
}


//  https://github.com/vueform/slider#styling
.slider-base {
  border: 1px solid $color-secondary !important;
}
.slider-handle {
  border: 2px solid $color-secondary !important;

  &:hover {
    background-color: #eaf7f1;
  }
}
.slider-handle:focus {
  box-shadow: 0 0 0 3px #87bca8 !important;
  outline: none;
}
.unlimited .slider-handle-upper .slider-tooltip-top {
  transform: translate(-85%, 0) !important;

  &:before {
    left: 85%;
  }
}

:root {
  --slider-bg: #e0e0e0;
  --slider-connect-bg: #cff2e4;
  --slider-connect-bg-disabled: #9CA3AF;
  --slider-height: 10px;
  --slider-vertical-height: 300px;
  --slider-radius: 9999px;

  --slider-handle-bg: #fff;
  --slider-handle-border: 0;
  --slider-handle-width: 28px;
  --slider-handle-height: 28px;
  --slider-handle-radius: 9999px;
  --slider-handle-shadow: none;
  --slider-handle-shadow-active: none;
  --slider-handle-ring-width: 0;
  --slider-handle-ring-color: none;

  --slider-tooltip-font-size: 1.6rem;
  --slider-tooltip-line-height: 1.8rem;
  --slider-tooltip-font-weight: 400;
  --slider-tooltip-min-width: 20px;
  --slider-tooltip-bg: #286052;
  --slider-tooltip-bg-disabled: #9CA3AF;
  --slider-tooltip-color: #fff;
  --slider-tooltip-radius: 5px;
  --slider-tooltip-py: 2px;
  --slider-tooltip-px: 6px;
  --slider-tooltip-arrow-size: 5px;
  --slider-tooltip-distance: 3px;
}