.v-toast {
  position: fixed;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 2em;
  overflow: hidden;
  z-index: 2147483647;
  pointer-events: none;

  &__item {
    align-items: center;
    animation-duration: 150ms;
    border-radius: 10px;
    border-top:  2px solid #FFFBFA;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.1);
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    font-family: $body-font-family;
    font-size: 1.6rem;
    margin: 0 2.4rem 2.4rem 2.4rem;
    min-height: 3em;
    padding: 1.6rem;
    pointer-events: auto;

    @include mq($from: tablet) {
      padding: 2.4rem;
    }

    // Colors
    @each $color, $value in $toast-colors {
      &--#{$color} {
        background-color: $value;
      }
    }

    @each $color, $value in $toast-text-colors {
      &--#{$color} {
        color: $value;
      }
    }

    // Individual toast position
    &.v-toast__item--top, &.v-toast__item--bottom {
      align-self: center;
    }

    &.v-toast__item--top-right, &.v-toast__item--bottom-right {
      align-self: flex-end;
    }

    &.v-toast__item--top-left, &.v-toast__item--bottom-left {
      align-self: flex-start;
    }
  }

  &__text {
    margin: 0;
    // padding: 0.5em 1em;
    word-break: break-word;
  }

  &__icon {
    display: none;
  }

  // Notice container positions
  &.v-toast--top {
    flex-direction: column;
  }

  &.v-toast--bottom {
    flex-direction: column-reverse;
  }

  &.v-toast--custom-parent {
    position: absolute;
  }

  @media screen and (max-width: 768px) {
    padding: 0;
    position: fixed !important;
  }

  // Our custom stuff.
  .toast-recipe-saved {
    display: flex;
    align-items: center;

    button {
      margin-left: 2.4rem;
      background-color: #CFF2E4;
    }
  }
}
