.c-btn-v2 {
  align-items: center;
  border-radius: 2.2rem;
  display: flex;
  font-size: 1.6rem;
  line-height: 4.4rem;
  padding: 0 1.6rem;
  position: relative;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px $color-brand-melon-50;
  }

  &__sm {
    border-radius: 1.4rem;
    font-size: 1.4rem;
    line-height: 2.8rem;
    padding: 0 1.4rem;

    &.c-btn-v2__secondary {
      line-height: 2.6rem;
    }
  }

  &__primary {
    background-color: $color-brand-melon-200;
    color: $color-brand-melon-700;

    &:hover {
      background-color: $color-brand-melon-300;
    }

    &--disabled,
    &--disabled.c-btn-v2__primary:hover {
      background-color: $color-ui-greyscale-200;
      color: $color-ui-greyscale-300;
    }
  }

  &__primary-green {
    background-color: $color-secondary;
    color: #fff;

    &:hover {
      background-color: #496e63;
    }

    &--active {
      background-color: #15352d;
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 3px #87bca8;
    }

    &--disabled,
    &--disabled.c-btn-v2__primary:hover {
      background-color: #dbdbdb;
      color: #acacac;
    }
  }

  &__secondary {
    background-color: $color-brand-melon-50;
    color: $color-brand-melon-700;

    &:focus {
      background-color: $color-brand-melon-200;
    }

    &:hover {
      background-color: $color-brand-melon-300;
    }

    &--disabled,
    &--disabled.c-btn-v2__secondary:hover {
      background-color: $color-ui-greyscale-200;
      color: $color-ui-greyscale-300;
    }
  }

  &__cta {
    background-color: #ffcebf;
    color: #000;

    &:hover {
      background-color: #f1beaf;
    }

    &--active {
      background-color: #a67667;
    }

    &:focus {
      box-shadow: 0 0 0 3px #000;
    }
  }

  &__grey {
    background-color: $color-ui-greyscale-50;
    color: $color-ui-greyscale-700;

    &:hover {
      background-color: $color-ui-greyscale-300;
    }

    &--disabled,
    &--disabled.c-btn-v2__grey:hover {
      background-color: $color-ui-greyscale-200;
      color: $color-ui-greyscale-300;
    }
  }

  &__secondary-green {
    background-color: #fff;
    border: 1px solid $color-secondary;
    color: $color-secondary;
    line-height: 4.2rem;

    &:focus {
      outline: none;
      box-shadow: 0 0 0 3px #87bca8;
    }

    &:hover {
      background-color: #eaf7f1;
    }

    &--active {
      background-color: #cff2e4;
    }

    &--disabled,
    &--disabled.c-btn-v2__secondary:hover {
      background-color: #fff;
      border-color: #dbdbdb;
      color: #acacac;
    }
  }

  &__ghost {
    color: $color-secondary;

    &:hover {
      color: #15352d;
    }

    &--active {
      color: #87bca8;
    }
  }

  &__icon {
    height: 1.8rem;
    width: auto;

    &--left {
      margin-right: 1.6rem;
    }

    &--right {
      margin-left: 1.6rem;
    }
  }

  &__badge {
    font-size: 12px;
    right: -0.5rem;
    top: -0.5rem;
    // background-color: #ffcebf;
    // color: #000;

    background-color: $color-secondary;
    // background-color: #15352d;
    color: #fff;

    position: absolute;
    line-height: initial;
    padding: 0.2rem 0.5rem;
    border-radius: 1rem;
  }
}

.c-btn-search-v2 {
  position: relative;
  box-sizing: border-box;

  input {
    -webkit-appearance: none !important;
    border-radius: 2.8rem;
    border: 2px solid #fff;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.18);
    box-sizing: border-box;
    font-size: 1.6rem;
    line-height: 5.2rem;
    padding-left: 2.8rem;
    padding-right: 6rem;
    width: 100%;

    &:hover {
      border: 2px solid $color-secondary;
    }

    &:focus {
      border: 2px solid $color-secondary;
      box-shadow: 0 0 1px 1px $color-secondary;
      outline: none;
    }

    &.bordered {
      border: 1px solid $color-secondary;
      line-height: 5.4rem;

      &:hover {
        border: 2px solid $color-secondary;
        line-height: 5.2rem;
      }
    }
  }

  button {
    background-color: $color-secondary;
    border-bottom-right-radius: 2.8rem;
    border-top-right-radius: 2.8rem;
    border: 1px solid $color-secondary;
    height: 5.6rem;
    position: absolute;
    right: 0;
    top: 0;
    width: 5.6rem;

    &:focus {
      outline: none;
    }

    img {
      display: inline;
    }
  }

  &--invalid {
    input {
      &:hover {
        border: 2px solid $color-ui-error-600;
      }

      &:focus {
        border: 2px solid $color-ui-error-600;
        box-shadow: 0 0 1px 1px $color-ui-error-600;
      }

      &.bordered {
        border: 1px solid $color-ui-error-600;

        &:hover {
          border: 2px solid $color-ui-error-600;
        }
      }
    }

    button {
      background-color: $color-ui-error-600;
      border: 1px solid $color-ui-error-600;
    }
  }
}
