// Large ads, small player.
.jw-flag-mini-sticky {
  @include mq($until: mobile) {
    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-wrapper {
      height: 88px;
      z-index: 1001 !important;
      max-height: 200px;
    }
  }

  @include mq($until: mobile) {

    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-video,
    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-preview,
    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-overlays {
      margin: 0;
      width: 40%;
      object-fit: cover;
    }
  }

  @include mq($until: mobile) {

    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-controls,
    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-controls-backdrop {
      left: 40%;
      width: 60%;
      background: #222;
    }
  }

  @include mq($until: mobile) {

    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-button-container,
    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-float-icon,
    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-title {
      display: flex !important;
      justify-content: flex-end;
      padding-top: 12px;
    }
  }

  /* FIXME: What is this strange css class .css-vkjmnj? */
  @include mq($until: mobile) {
    .css-vkjmnj .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-float-icon.jw-icon {
      right: 0px;
      margin-right: 10px;
    }
  }

  @include mq($until: mobile) {

    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-text-elapsed,
    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-text-countdown,
    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-text-duration,
    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-display,
    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-spacer,
    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-slider-time,
    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-settings-sharing,
    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-icon-settings,
    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-icon-fullscreen,
    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-horizontal-volume-container,
    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-related-btn,
    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-icon-fullscreen.jw-fullscreen-ima,
    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-slider-volume {
      display: none !important;
    }
  }

  /* FIXME: What is this strange css class .css-vkjmnj? */
  @include mq($until: mobile) {
    .css-vkjmnj .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-button-container {
      justify-content: flex-start;
      padding: 0px;
    }
  }

  @include mq($until: mobile) {
    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-button-container {
      padding: 0 16px 8px 0;
    }
  }

  @include mq($until: mobile) {

    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-controls,
    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-controlbar,
    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-float-icon,
    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-icon-playback,
    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-icon-fullscreen,
    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-button-container,
    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-controls-backdrop {
      opacity: 1 !important;
      display: flex !important;
      transform: none !important;
      visibility: visible !important;
    }
  }

  @include mq($until: mobile) {
    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-icon {
      width: 32px;
      color: #fff;
      cursor: pointer;
      pointer-events: all;
      justify-content: flex-end;
    }
  }

  @include mq($until: mobile) {
    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-title-primary::before {
      content: 'Nu spelas: ';
      font-size: 14px;
      font-weight: 100;
      white-space: normal;
      line-height: 23px;
    }

    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-title-primary {
      font-weight: bold;
      padding-left: 42%;
      font-size: 14px;
      padding-right: 33px;
    }
  }

  @include mq($until: mobile) {
    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-float-icon.jw-icon {
      right: 0;
      margin-right: 8px;
      top: -3px;
    }
  }

  @include mq($until: mobile) {
    .jw-flag-floating.jw-flag-floating:not(.jw-flag-ads) .jw-button-container {
      justify-content: flex-start;
      padding: 0;
    }
  }

  @include mq($until: mobile) {
    .jw-flag-small-player .jw-nextup-container .jw-nextup.jw-nextup-thumbnail-visible {
      display: none;
    }
  }
}

@include mq($until: mobile) {

  .jw-flag-floating .jw-ads-view>div,
  .jw-flag-floating .jw-ads-view iframe {
    height: 100% !important;
  }
}
