/**
 * [1] Settings         Scss vars, used with preprocessors and contain font, colors definitions, etc.
 * [2] Tools            Globally used mixins and functions.
 * [3] Generics         Reset and/or normalize styles, box-sizing definition, etc. This is the first layer which generates actual CSS.
 * [4] Elements         Styling for bare HTML elements (like H1, A, etc.). These come with default styling from the browser so we can redefine them here.
 * [5] Objects          Objects and abstractions, class-based selectors which define undecorated design patterns, for example media object known from OOCSS.
 * [6] Components       Specific UI components. This is where majority of our work takes place and our UI components are often composed of Objects and Components.
 * [7] Utilities        Overrides and helper classes with ability to override anything which goes before in the triangle. !important is often used.
 */

/* ----------------------------------------------------------------------
[1] Settings
---------------------------------------------------------------------- */
@import "~inuitcss/settings/settings.core";
@import 'variables';

/* ----------------------------------------------------------------------
[2] Tools & Resources
---------------------------------------------------------------------- */
// Media Queries with superpowers (https://github.com/sass-mq/sass-mq):
@import '~sass-mq';

// Font Awesome Pro.
// @import "~@fortawesome/fontawesome-pro/scss/fontawesome";
// @import "~@fortawesome/fontawesome-pro/scss/light";
// @import "~@fortawesome/fontawesome-pro/scss/regular";
// @import "~@fortawesome/fontawesome-pro/scss/solid";
// @import "~@fortawesome/fontawesome-pro/scss/brands";

// Tailwind.
@import "~tailwindcss/base";
@import "~tailwindcss/components";
@import "~tailwindcss/utilities";

/* ----------------------------------------------------------------------
[3] Generics
---------------------------------------------------------------------- */
@import "~inuitcss/generic/generic.normalize";
@import "~inuitcss/generic/generic.reset";
@import 'generics/resets';

/* ----------------------------------------------------------------------
[4] Elements
---------------------------------------------------------------------- */
@import 'web/elements/base';
@import 'web/elements/headings';
@import 'web/elements/lists';
@import 'web/elements/forms';
@import 'web/elements/card';

/* ----------------------------------------------------------------------
[6]  Components
---------------------------------------------------------------------- */
@import 'shared/article-content';
@import 'web/components/vue-toast-notification/index.scss';

// Layouts:
@import 'web/layout/miscellaneous';

// Components:
@import 'web/components/ads';
@import 'web/components/articles';
@import 'web/components/buttons';
@import 'web/components/comments';
@import 'web/components/dropdown';
@import 'web/components/forms';
@import 'web/components/login-button';
@import 'web/components/login-modal';
@import 'web/components/modal';
@import 'web/components/modal-v2';
@import 'web/components/primary-nav';
@import 'web/components/video';
@import 'web/components/cmp';

/* ----------------------------------------------------------------------
[7]  Utilities
---------------------------------------------------------------------- */
@import "~@vueform/slider/themes/default.scss";