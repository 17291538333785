.c-comments {
    &__title {
        font-family: $headline-font-family;
        font-size: 2.5rem;
        font-weight: 700;

        span {
            color: $color-secondary;
            font-size: small;
            font-weight: normal;
        }
    }

    &__thread {
        border-top: 1px #DBDBDB solid;
        padding-top: 2.4rem;
        margin-bottom: 2.4rem;
    }

    &__replies {
        border-left: 4px #DBDBDB solid;
        padding-left: 1.6rem;
        margin-top: 3.2rem;

        .c-comment {
            border-top: 1px #DBDBDB solid;
            padding-top: 2rem;
            margin-bottom: 2.4rem;

            &--first {
                border-top: none !important;
                padding-top: .4rem !important;
            }
        }

        .c-comment-form-edit {
            border-top: 1px #DBDBDB solid;
            padding-top: 2rem;
            margin-bottom: 2.4rem;
        }
    }

    &__reply-form {
        &__respond-to {
            margin: 2.4rem 0 1.2rem;
            font-size: 1.5rem;
            font-weight: 700;
        }
    }

    &__more {
        border-top: 1px #DBDBDB solid;
        padding-top: 3rem;

        button {
            margin: 0 auto;
        }
    }

    .error {
        color: #ff3333;
    }
}

.c-comment {
    font-family: $body-font-family;

    &__new {
        margin: 2.4rem 0;
    }

    &__meta {
        display: flex;
        font-size: 1.5rem;

        img {
            margin-right: .6rem;
        }

        .name {
            flex-grow: 1;
            font-weight: 700;
        }

        .date {
            color: #757575;
        }
    }

    &__body {
        margin: .5rem 0 1rem;

        p,
        ul,
        ol, {
            font-size: 1.6rem;
            margin-bottom: 1rem;

            a {
                color: $color-secondary;
                font-weight: bold;
                text-decoration: underline;
            }

            li {
                list-style-position: inside;
                margin-left: 1rem;
                margin-bottom: 0.25rem;
            }
        }

        ul {
            list-style: initial;

            li {
                list-style-type: disc;
            }
        }

        ol {
            list-style: initial;

            li {
                list-style-type: decimal;
            }
        }
    }

    &__like {
        &--liked {
            background-color: #CFF2E4;
        }
        span {
            margin-left: 8px;
        }
    }

    &__spam {
        font-size: 1.6rem;
        color: $color-ui-error-600;

        a {
            font-weight: bolder;
        }
    }

    &__actions {
        display: flex;

        > button {
            margin-right: 1.2rem;

            &:last-of-type {
                margin-right: 0;
            }
        }
    }

    &.highlight {
        background-color: cornsilk;
        padding: 1rem;
        border-radius: 10px;
    }
}

.c-comment-form {
    display: flex;
    flex-direction: column;

    > div {
        margin-bottom: 1.2rem;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &__actions {
        display: flex;
        justify-content: right;

        > button {
            margin-right: 1.2rem;

            &:last-of-type {
                margin-right: 0;
            }
        }

        .delete-spacer {
            flex-grow: 1;
        }
    }

    .c-input-text-v2 {
        min-width: 100%;
        max-width: 100%;
        width: 100%;
    }

    &__error-msg {
        color: $color-ui-error-600;
        font-size: 1.4rem;
    }

    &__admin-name {
        display: flex;

        img {
            margin-right: .6rem;
        }

        .name {
            flex-grow: 1;
        }
    }

    &__help {
        color: #959595;
        font-size: 1.2rem;
        margin-top: .5rem;
    }

    &__usage-text {
        font-size: 1.2rem;
        margin: .5rem 0;

        a {
            text-decoration: underline;
            font-weight: 600;
        }
    }

    .error {
        color: $color-ui-error-600;
        font-size: 1.6rem;
    }
}

.c-comment-form-edit {
    &__title {
        font-size: 1.5rem;
        font-weight: 700;
    }
}

