.c-login-modal.c-modal {
    .modal {
        text-align: left;
    }

    .modal-wrapper {
        max-width: 44rem;
    }

    .actions {
        margin-bottom: 1.6rem;
        margin-top: 50px;
    }

    .benefit {
        align-items: center;
        display: flex;
        margin: 15px 0;
        flex-direction: row;

        &__image {
            margin-right: 2.2rem;
        }

        &__text {
            font-family: $body-font-family;
            font-size: 16px;
            line-height: 22px;
        }
    }

    @include mq($from: mobile) {
        h1,
        .benefits,
        .actions {
            margin-left: 1.6rem;
            margin-right: 1.6rem;
        }
    }
}